.App {
  text-align: center;
  display: flex;
  background-color: #282c34;
  color: white;
  position: relative;
  top: 0;bottom: 0;left: 0;right: 0;
  display: flex;
  flex-direction: row; /* Stack children vertically */
  height: 100vh; /* Full viewport height */
  align-items: stretch;
}

.sidemenu {
  width: 260px;
  padding: 10px;
  background-color: #171717;
}

.sidemenu-button{
  padding: 12px;
  border: 1px solid white;
  border-radius: 5px;
  text-align: left;
  transition: ease 0.25s all;
}

.sidemenu-button:hover{
  background-color: rgba(255, 255, 255, 0.1);
}

.sidemenu-button span{
  padding-left:6px; padding-right: 10px;
}

.chat-log{
  text-align: left;
  /*max-height: 500px; /* Adjust based on your layout */
  overflow-y: auto; /* Enables vertical scrolling */
  flex: 1; /* Grow to fill available space */
  overflow-y: auto; /* Keep the scrollbar for overflow */
  
}

.chat-message-center{
  display:flex;
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
  padding: 12px;
  padding-left: 40px;
  padding-right: 40px;
  
}
.chat-message.chatgpt{
  background-color: #444654;
}
.chat-message{
  
  }

.avatar.chatgpt{
  background-color: #0da37f;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}

.avatar{
  background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
}

.message{ 
  padding-left: 40px;
  padding-right: 40px;
}

.chat-input-text-area{
  background-color: #40414f;
  width: 95%;
  padding: 12px;
  color: white;
  font-size: 1.25em;

  border-radius: 5px; 
  border: none;
  margin: 12px;
  outline: none;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  overflow-y: auto; /* Allows the textarea to grow vertically */
  min-height: 25px; /* Minimum height before user starts typing */
  max-height: 1000px; /* Maximum height before scrolling within textarea */
  resize: none; /* Prevents user from manually resizing the textarea */
}

.chat-input-holder{
  position: relative;
  padding: 24px;
  bottom: 0;
  left: 0; right: 0;

}

.chatbox {
  flex: 1;
  border: 1px solid #d3d3d3;
  background-color: #212121;
  position: relative;
  flex: 1; /* This allows the chatbox to grow and fill available space */
  display: flex;
  flex-direction: column; /* Ensure internal layout is vertically oriented */ 
  height: 100%; /* Fill available vertical space */
}

.forecast-parameters {
  padding: 10px;
  background-color: #232834;
  border: 1px solid #d3d3d3;
  
}

/* get the sizing of the columns right */
.chatbox, .forecast-parameters {
  flex: 1;
  border: 1px solid #d3d3d3;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mobile-controls{
  display: none;
}

.sidemenu-toggle{
  display: none;
}

.main-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  /* Ensure it's part of the flex layout */
}
.page-content
{
  display: flex;
  flex-direction: row; /* Align children vertically */
  flex-grow: 1; /* Take up all available space */
  width: 100%; /* Cover full width */
  position: relative; /* If you are positioning children absolutely */
}
/* Mobile specific styles */
@media (max-width: 768px) {
  .mobile-toggle-buttons {
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
  }
  .main-content {
    display: flex;
    flex-direction: column; /* Align children vertically */
    flex-grow: 1; /* Take up all available space */
    width: 100%; /* Cover full width */
    position: relative; /* If you are positioning children absolutely */
  }
  .page-content {
    flex-grow: 1; /* Take up all available space */
    width: 100%; /* Cover full width */
    flex-direction: column;
  }
  .mobile-controls {
    display: block; /* Show the mobile controls */
  }
  .mobile-hidden {
    display: none;
  }
  .mobile-toggle-buttons button {
    flex-grow: 1;
    padding: 10px;
    background: #f0f0f0;
    border: 1px solid #ccc;
    font-size: 16px;
  }

  .mobile-toggle-buttons button.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
  
  .sidemenu {
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(-100%); /* Start off-screen */
    width: 75%; /* Adjust based on your preference */
    height: 100%;
    background-color: #333; /* Example color */
    transition: transform 0.3s ease-out;
    z-index: 1000; /* Ensure it's above other content */
    padding-right: 50px;
    padding-left: 20px;
}

  .sidemenu:not(.sidemenu-hidden) {
    transform: translateX(0); /* Show when toggled */
  }

  .menu-toggle, .forecast-toggle {
    position: fixed;
    z-index: 1010; /* Above the side menu */
    top: 10px; /* Adjust as needed */
    left: 10px; /* Adjust as needed */
    cursor: pointer;
  }

  .sidemenu-toggle {
    display: block; /* Show the toggle button */
    position: absolute;
    top: 20px;
    left: 0px;
    z-index: 1001;
    cursor: pointer;
    background-image: url('./icons/menu-bar.png'); /* Adjust the path accordingly */
    background-size: cover; /* or contain, based on how you want the icon to fit */
    width: 50px; /* Adjust based on your icon's size */
    height: 50px; /* Adjust based on your icon's size */
    background-color: transparent; /* If you don't want any background color */
    border: none; /* Remove border if not needed */
    transition: 0.3s ease; /* Smooth transition */
  }

  .chatbox {
    transition: opacity 0.3s ease;
    height: 100%; /* Fill available vertical space */
  }

  .forecast-parameters{
    transition: opacity 0.3s ease;
  }

  .sidemenu-open ~ .main-content, .sidemenu-open ~ .chatbox, .sidemenu-open ~ .forecast-parameters {
    opacity: 0.5; /* Dim the content when menu is open */
}
  /* Adjust the position of the toggle button when it is pressed to stay on the side of the sidebar*/

  .sidemenu-toggle-open {
    left: calc(67%); /* Adjust based on sidebar width */
    background-image: url('./icons/close-menu-icon.png'); /* Adjust the path accordingly */
    width: 25px;
    height: 25px;
    top: 35px;
  }
  .chatbox-active .chatbox,
  .forecast-parameters-active .forecast-parameters {
    display: block; /* Only show the active section */
  }

  .toggle-button {
    font-size: 0.8rem; /* Small font size for the buttons */
    margin: 0 5px; /* Spacing between buttons */
    padding: 5px 10px; /* Small padding, slightly bigger than one row of text */
    background-color: #444; /* Example background color */
    color: #fff; /* Text color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .toggle-button.active {
    background-color: #666; /* Darker background for active state */
  }
  
  .toggle-button:hover {
    background-color: #555; /* Hover state */
  }
  
}